import React, { useState, useEffect } from 'react';

const PaceSpeedConverter = () => {
  const [paceMinutes, setPaceMinutes] = useState(4);
  const [paceSeconds, setPaceSeconds] = useState(0);
  const [speed, setSpeed] = useState(15); // km/h

  const initialDistances = {
    30: 125,
    60: 250,
    120: 500,
    240: 1000,
    480: 2000,
    2400: 10000
  };

  const [distances, setDistances] = useState(initialDistances);

  const initialTimesForDistances = {
    0.4: { hours: 0, minutes: 1, seconds: 36 },
    0.5: { hours: 0, minutes: 2, seconds: 0 },
    1: { hours: 0, minutes: 4, seconds: 0 },
    1.60934: { hours: 0, minutes: 6, seconds: 26 },
    10: { hours: 0, minutes: 40, seconds: 0 },
    21: { hours: 1, minutes: 24, seconds: 0 },
    42: { hours: 2, minutes: 48, seconds: 0 }
  };

  const [timesForDistances, setTimesForDistances] = useState(initialTimesForDistances);

  // These state variables are used to store user inputs for adding new distance and new time entries.
  const [newDistance, setNewDistance] = useState('');
  const [newTime, setNewTime] = useState('');

  const [panelVisibility, setPanelVisibility] = useState({
    converter: true,
    timeForDistances: true,
    distanceInTime: true
  });
  // Calculate the total pace in minutes
  // Example: 4 minutes and 30 seconds is 4.5 minutes
  const totalPaceInMinutes = paceMinutes + paceSeconds / 60;





  useEffect(() => {
    // Update speed when pace changes

    //totalPaceInMinutes = paceMinutes + paceSeconds / 60;
    // Update speed when pace changes
    setSpeed(Number((60 / totalPaceInMinutes)));
  }, [paceMinutes,paceSeconds]);



  useEffect(() => {
    // Update pace when speed changes


    // Example: 13 km/h is 4 minutes and 36.9 seconds
    let paceInMinutes = 60 / speed; // 60 / 13 = 4.615384615384615
    let minutes = Math.floor(paceInMinutes); // Math.floor(4.615384615384615) is 4
    let seconds = (paceInMinutes - minutes) * 60; // 0.615384615384615 * 60) is 36.9


    // Sanitize inputs
    if (seconds >= 60) {
      seconds = 0;
      minutes = minutes + 1;
    }
    if (seconds < 0) {
      seconds = 59;
      minutes = minutes - 1;
    }
    setPaceMinutes(minutes);
    setPaceSeconds(seconds);

    // Update distances when speed changes
    const newDistances = Object.fromEntries(
      Object.entries(distances).map(([time, _]) => [time, calculateDistance(Number(time))])
    );
    setDistances(newDistances);

    // Update times for distances when speed changes, unless user-modified
    setTimesForDistances((prevTimes) => {
      const updatedTimes = {};
      for (const [distance, timeObj] of Object.entries(prevTimes)) {
          updatedTimes[distance] = calculateTimeObject(Number(distance));
        }
        
      
      return updatedTimes;
    });
  }, [speed]);

  const handlePaceChange = (minutes, seconds) => {
    setPaceMinutes(Number(minutes));
    setPaceSeconds(Number(seconds));
  };

  const handleSpeedChange = (value) => {
    setSpeed(Number(value));
  };

  const handleDistanceChange = (time, newDistance) => {
    const newSpeed = (newDistance / 1000 / (time / 3600));
    setSpeed(Number(newSpeed));
  };

  const handleTimeForDistanceChange = (distance, hours, minutes, seconds) => {

    // Sanitize inputs
    if (seconds > 59) {
      seconds = 0;
      minutes = minutes + 1;
    }
    if (seconds < 0) {
      seconds = 59;
      minutes = minutes - 1;
    }
    if (minutes > 59) {
      minutes = 0;
      hours = hours + 1;
    }
    if (minutes < 0) {
      minutes = 59;
      hours = hours - 1;
    }

    // Calculate total time in hours without updating speed immediately
    const totalTimeInHours = (Number(hours) + Number(minutes) / 60 + Number(seconds) / 3600);

    // Calculate new speed but do not update it immediately
    const newSpeed = Number(distance) / totalTimeInHours;
    

    // Only update speed if the total time is valid (non-zero)
    if (totalTimeInHours > 0) {
      setSpeed(Number(newSpeed));
    }

    // Update the specific time component for the distance
    setTimesForDistances(prev => ({
      ...prev,
      [distance]: { hours: Number(hours), minutes: Number(minutes), seconds: Number(seconds) }
    }));

    
  };

  const formatDistance = (distanceInKm) => {
    return distanceInKm < 1 
      ? `${(parseFloat(distanceInKm).toFixed(2) * 1000)}m` 
      : `${parseFloat(distanceInKm).toFixed(2)}km`;
  };

  const calculateDistance = (timeInSeconds) => (speed / 3600) * timeInSeconds * 1000;

  const calculateTimeObject = (distance) => {
    const totalHours = distance / speed;
    let hours = Math.floor(totalHours);
    let minutes = Math.floor((totalHours - hours) * 60);
    let seconds = ((totalHours - hours) * 60 - minutes) * 60;

    if (seconds >= 59) {
      seconds = 0;
      minutes = minutes + 1;
    }
    if (seconds < 0) {
      seconds = 59;
      minutes = minutes - 1;
    }

    return { hours, minutes, seconds };
  };

  const formatTimeForDistanceInTime = (seconds) => {
    if (seconds < 60) {
      return `${seconds}s`;
    } else {
      const minutes = Math.floor(seconds / 60);
      const remainingSeconds = seconds % 60;
      return `${minutes}m ${remainingSeconds}s`;
    }
  };

  const handleAddNewDistance = (unit) => {
    if (newDistance && !isNaN(newDistance) && Number(newDistance) > 0) {
      const distanceInKm = unit === 'km' ? Number(newDistance) : Number(newDistance) / 1000;
      setTimesForDistances(prev => ({
        ...prev,
        [distanceInKm]: { ...calculateTimeObject(distanceInKm) }
      }));
      setNewDistance('');
    }
  };

  const handleAddNewTime = (unit) => {
    if (newTime && !isNaN(newTime) && Number(newTime) > 0) {
      let timeInSeconds;
      switch (unit) {
        case 'hours':
          timeInSeconds = Number(newTime) * 3600;
          break;
        case 'minutes':
          timeInSeconds = Number(newTime) * 60;
          break;
        default:
          timeInSeconds = Number(newTime);
      }
      setDistances(prev => ({
        ...prev,
        [timeInSeconds]: calculateDistance(timeInSeconds)
      }));
      setNewTime('');
    }
  };

  const togglePanel = (panel) => {
    setPanelVisibility(prev => ({
      ...prev,
      [panel]: !prev[panel]
    }));
  };

  return (
    <div className="space-y-4 w-full max-w-xl mx-auto px-4 sm:px-6 lg:px-8">
      <h1 className="text-2xl sm:text-3xl font-bold text-center my-4 sm:my-6">Pace Speed Converter</h1>
      <p className="text-center text-gray-600 mb-6 sm:mb-8 text-sm sm:text-base">
        Convert between pace and speed, calculate times for various distances, and determine distances covered in specific time intervals.
      </p>
      
      <div className="border rounded-lg p-3 sm:p-4">
        <div className="flex justify-between items-center mb-2">
          <h2 className="text-lg font-bold">Pace/Speed Converter</h2>
          <button onClick={() => togglePanel('converter')} className="px-2 py-1 bg-gray-200 rounded">
            {panelVisibility.converter ? '▲' : '▼'}
          </button>
        </div>
        {panelVisibility.converter && (
          <div className="space-y-4">
            <div>
              <label className="block mb-1">Pace (min:sec/km)</label>
              <div className="flex items-center space-x-2">
                <input
                  type="number"
                  value={paceMinutes}
                  onChange={(e) => handlePaceChange(e.target.value, paceSeconds)}
                  className="w-20 p-2 border rounded"
                  min="0"
                />
                <span>:</span>
                <input
                  type="number"
                  value={Math.round(paceSeconds)}
                  onChange={(e) => handlePaceChange(paceMinutes, e.target.value)}
                  className="w-20 p-2 border rounded"
                  min="-1"
                  max="61"
                />
              </div>
            </div>
            <div>
              <label htmlFor="speed" className="block mb-1">Speed (km/h)</label>
              <input
                id="speed"
                type="number"
                value={parseFloat(speed).toFixed(1)}
                step="0.1"
                onChange={(e) => handleSpeedChange(e.target.value)}
                className="w-full p-2 border rounded"
              />
            </div>
            <div>
              <label htmlFor="speedSlider" className="block mb-1">Adjust Speed</label>
              <input
                id="speedSlider"
                type="range"
                min="0"
                max="30"
                step="0.1"
                value={parseFloat(speed).toFixed(1)}
                onChange={(e) => handleSpeedChange(e.target.value)}
                className="w-full"
              />
            </div>
          </div>
        )}
      </div>

      <div className="border rounded-lg p-3 sm:p-4">
        <div className="flex justify-between items-center mb-2">
          <h2 className="text-lg font-bold">Time for Distances</h2>
          <button onClick={() => togglePanel('timeForDistances')} className="px-2 py-1 bg-gray-200 rounded">
            {panelVisibility.timeForDistances ? '▲' : '▼'}
          </button>
        </div>
        {panelVisibility.timeForDistances && (
          <div className="space-y-2">
            {Object.entries(timesForDistances)
              .sort(([a], [b]) => Number(a) - Number(b))
              .map(([distance, time]) => (
                <div key={distance} className="flex flex-col sm:flex-row sm:items-center sm:space-x-2 mb-2">
                  <span className="w-full sm:w-20 mb-1">{formatDistance(Number(distance))}:</span>
                  <div className="flex flex-wrap items-center space-x-2">
                    <div className="flex items-center space-x-1 mb-1 sm:mb-0">
                      <input
                        type="number"
                        value={time.hours}
                        onChange={(e) => handleTimeForDistanceChange(distance, e.target.value, time.minutes, time.seconds)}
                        className="w-14 p-1 border rounded text-right"
                        min="0"
                      />
                      <span className="w-6">h</span>
                    </div>
                    <div className="flex items-center space-x-1 mb-1 sm:mb-0">
                      <input
                        type="number"
                        value={time.minutes}
                        onChange={(e) => handleTimeForDistanceChange(distance, time.hours, e.target.value, time.seconds)}
                        className="w-14 p-1 border rounded text-right"
                        min="-1"
                        max="60"
                      />
                      <span className="w-6">m</span>
                    </div>
                    <div className="flex items-center space-x-1">
                      <input
                        type="number"
                        value={Math.round(time.seconds)}
                        onChange={(e) => handleTimeForDistanceChange(distance, time.hours, time.minutes, e.target.value)}
                        className="w-14 p-1 border rounded text-right"
                        min="-1"
                        max="60"
                      />
                      <span className="w-6">s</span>
                    </div>
                  </div>
                </div>
              ))}
            <div className="flex flex-col space-y-2 mt-4">
              <input
                type="number"
                value={newDistance}
                onChange={(e) => setNewDistance(e.target.value)}
                placeholder="New distance"
                className="w-full p-2 border rounded"
                step="0.01"
                min="0"
              />
              <div className="flex space-x-2">
                <button onClick={() => handleAddNewDistance('m')} className="flex-1 px-2 py-2 bg-blue-500 text-white rounded text-sm">Add Meters</button>
                <button onClick={() => handleAddNewDistance('km')} className="flex-1 px-2 py-2 bg-blue-500 text-white rounded text-sm">Add Kilometers</button>
              </div>
            </div>
          </div>
        )}
      </div>

      <div className="border rounded-lg p-3 sm:p-4">
        <div className="flex justify-between items-center mb-2">
          <h2 className="text-lg font-bold">Distance in Time</h2>
          <button onClick={() => togglePanel('distanceInTime')} className="px-2 py-1 bg-gray-200 rounded">
            {panelVisibility.distanceInTime ? '▲' : '▼'}
          </button>
        </div>
        {panelVisibility.distanceInTime && (
          <div className="space-y-2">
            {Object.entries(distances).map(([time, distance]) => (
              <div key={time} className="flex items-center space-x-2">
                <span className="w-20">{formatTimeForDistanceInTime(Number(time))}:</span>
                <input
                  type="number"
                  value={Math.round(distance)}
                  onChange={(e) => handleDistanceChange(time, e.target.value)}
                  className="w-20 p-1 border rounded"
                  step="1"
                />
                <span className="w-8">m</span>
                <span className="w-22">({(distance / 1000).toFixed(3)} km)</span>
              </div>
            ))}
            <div className="flex flex-col space-y-2 mt-4">
              <input
                type="number"
                value={newTime}
                onChange={(e) => setNewTime(e.target.value)}
                placeholder="New time"
                className="w-full p-1 border rounded"
                step="1"
                min="0"
              />
              <div className="flex space-x-2">
                <button onClick={() => handleAddNewTime('hours')} className="flex-1 px-2 py-1 bg-blue-500 text-white rounded">Add Hours</button>
                <button onClick={() => handleAddNewTime('minutes')} className="flex-1 px-2 py-1 bg-blue-500 text-white rounded">Add Minutes</button>
                <button onClick={() => handleAddNewTime('seconds')} className="flex-1 px-2 py-1 bg-blue-500 text-white rounded">Add Seconds</button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default PaceSpeedConverter;
